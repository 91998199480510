import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { useEffect, useRef } from "react";
import { hiwActions } from "../../../store/hiw.slice";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const HowItWorksForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const hiw = useSelector((state: any) => state.hiw.activeHIW);

    const titleRef = useRef<HTMLInputElement>(null);

    titleRef.current?.setAttribute("value", hiw.title);
    useEffect(() => {
        if (id) {
            dispatch(hiwActions.getHIWById(Number(id)));
        }
    }, [dispatch, id]);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const hiwTemp = { ...hiw, id: Number(id) };
        hiwTemp.title = e.target.value;
        dispatch(hiwActions.setActiveHIW(hiwTemp));
    };
    const handleDescriptionChange = (htmlData: string) => {
        const hiwTemp = {
            ...hiw,
            title: titleRef.current?.value,
            id: Number(id),
        };
        hiwTemp.content = htmlData;
        dispatch(hiwActions.setActiveHIW(hiwTemp));
    };

    const submitBtnClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (id) {
            dispatch(hiwActions.updateHIW(hiw));
        } else {
            dispatch(hiwActions.createHIW(hiw));
        }
        navigate("/dashboard/how-it-works");
    };

    function uploadAdapter(loader: any) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    console.log("upload");
                    loader.file.then((file: any) => {
                            body.append("image", file);
                            console.log(file);

                        fetch(
                            `https://10to90app.shieldssgf.dev/api/upload-image`,
                            {
                                method: "post",
                                body: body,
                            }
                        )
                            .then((res) => res.json())
                            .then((res) => {
                                resolve({
                                    default: res.url,
                                });
                            })
                            .catch((err) => {
                                console.log(err);
                                reject(err);
                            });
                    });
                });
            },
        };
    }
    function uploadPlugin(editor: any) {
        editor.plugins.get("FileRepository").createUploadAdapter = (
            loader: any
        ) => {
            return uploadAdapter(loader);
        };
    }
    return (
        <div className="w-75 m-auto">
            <div className="mt-3">
                <h2>How It Works Form</h2>
            </div>
            <div>
                <div className="form-group mt-3">
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Enter Title"
                        value={hiw.title}
                        onChange={handleTitleChange}
                        ref={titleRef}
                    />
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="description">Content</label>
                    <CKEditor
                        config={{
                            extraPlugins: [uploadPlugin],
                            image: {
                                upload: {
                                    types: [
                                        "jpeg",
                                        "png",
                                        "gif",
                                        "bmp",
                                        "svg+xml",
                                        "jpg",
                                    ],
                                },
                            },
                        }}
                        editor={ClassicEditor}
                        data={hiw.content}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            handleDescriptionChange(data);
                        }}
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-primary mt-3"
                    onClick={submitBtnClickHandler}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default HowItWorksForm;
