import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authApi from "../api/auth.api";
import { uiActions } from "./ui.slice";

interface Credentials {
    email: string;
    password: string;
}

const login = createAsyncThunk(
    "auth/login",
    async ({ email, password }: Credentials, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            const loginRes = await authApi.login(email, password);
            localStorage.setItem("token", loginRes.token);
            localStorage.setItem("user", JSON.stringify(loginRes.user));
            return loginRes;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        token: null,
        isLoggedIn: localStorage.getItem("token") ? true : false,
    },
    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.isLoggedIn = false;
            localStorage.removeItem("token");
            localStorage.removeItem("user");
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isLoggedIn = true;
        });
    },
});

export const authActions = {...authSlice.actions, login};
export default authSlice.reducer;
