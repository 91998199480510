import Navigation from "./Navigation"

const Header = () => {
    console.log("Header Created")
  return (
    // bootstrap navbar
   <>
    <Navigation/>
   </>
  )
}

export default Header
