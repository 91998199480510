import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../auth/Login/Login";
import DropOffLocations from "../dashboard/DropOffLocations/DropOffLocations";
import Users from "../dashboard/Users/Users";
import Protected from "./Protected";
import UserDetail from "../dashboard/Users/UserDetail";
import PublicRoute from "./PublicRoute";
import DropOffLocationDetail from "../dashboard/DropOffLocations/DropOffLocationDetail";
import Bagdrops from "../dashboard/Bagdrops/Bagdrops";
import HowItWorks from "../dashboard/HowItWorks/HowItWorks";
import HowItWorksForm from "../dashboard/HowItWorks/HowItWorksForm";

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="dashboard">
                <Route path="users">
                    <Route path="" element={<Protected><Users /></Protected>} />
                    <Route path="add" element={<Protected><UserDetail /></Protected>} />
                    <Route path=":id" >
                        <Route path="bagdrop" element={<Protected><Bagdrops /></Protected>} />
                        <Route path="" element={<Protected><UserDetail /></Protected>} />
                    </Route>
                </Route>
                <Route path="drop-off-locations">
                    <Route path="" element={<Protected><DropOffLocations /></Protected>} />
                    <Route path="add" element={<Protected><DropOffLocationDetail /></Protected>} />
                    <Route path=":id" element={<Protected><DropOffLocationDetail /></Protected>} />
                </Route>
                <Route path="how-it-works">
                    <Route path="" element={<Protected><HowItWorks /></Protected>} />
                    <Route path="add" element={<Protected><HowItWorksForm /></Protected>} />
                    <Route path=":id" element={<Protected><HowItWorksForm /></Protected>} />
                </Route>
            </Route>
        </Routes>
    );
};

export default Router;
