import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        loading: false,
    },
    reducers: {
        startLoading: (state: any) => {
            state.loading = true;
        },
        stopLoading: (state: any) => {
            state.loading = false;
        }
    }
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;