import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

interface PublicRouteProps {
    children: React.ReactElement;
}
const PublicRoute: React.FC<PublicRouteProps> = ({children}) => {
    const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

    if (isLoggedIn) {
        return (<Navigate to="/dashboard/users" />);
    }
    return children;
}

export default PublicRoute
