import { BASE_URL } from "../consts";

const getAll = async () => {
    const response = await fetch(`${BASE_URL}/api/howItWorks`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const get = async (id: number) => {
    const response = await fetch(`${BASE_URL}/api/howItWorks/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const create = async (hiw: any) => {
    const response = await fetch(`${BASE_URL}/api/howItWorks`, {
        method: "POST",
        body: JSON.stringify(hiw),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const update = async (id: number, hiw: any) => {
    const response = await fetch(`${BASE_URL}/api/howItWorks/${id}`, {
        method: "PUT",
        body: JSON.stringify(hiw),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const remove = async (id: number) => {
    const response = await fetch(`${BASE_URL}/api/howItWorks/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const hiwApi = {
    getAll,
    get,
    create,
    update,
    remove
};

export default hiwApi;
