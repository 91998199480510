import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userApi from "../api/user.api";
import { User, UserRole } from "../models/User.model";
import { uiActions } from "./ui.slice";

interface UserState {
    users: User[];
    activeUser: User | null;
}

const getUsers = createAsyncThunk("user/getAll", async (_, thunkAPI) => {
    try {
        thunkAPI.dispatch(uiActions.startLoading());
        const users = await userApi.getUsers();
        return users;
    } catch (error) {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
    } finally {
        thunkAPI.dispatch(uiActions.stopLoading());
    }
});

const createUser = createAsyncThunk(
    "user/create",
    async (user: User, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            const newUser = await userApi.createUser(user);
            return newUser;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

const updateUser = createAsyncThunk('user/update', async (user: User, thunkAPI) => {
    try {
        thunkAPI.dispatch(uiActions.startLoading());
        if (!user.id) {
            throw new Error("Problem with user id");
        }
        const updatedUser = await userApi.updateUser(user.id, user);
        return updatedUser;
    } catch (error) {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
    } finally {
        thunkAPI.dispatch(userActions.setActiveUser(initialState.activeUser));
        thunkAPI.dispatch(uiActions.stopLoading());
    }
});

const deleteUser = createAsyncThunk(
    "user/delete",
    async (id: number, thunkAPI) => {
        try {
            const deleteRes = await userApi.deleteUser(id);
            console.log(deleteRes.message);
            return id;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const getUser = createAsyncThunk("user/get", async (id: number, thunkAPI) => {
    try {
        thunkAPI.dispatch(uiActions.startLoading());
        const user = await userApi.getUser(id);
        return user;
    } catch (error) {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
    } finally {
        thunkAPI.dispatch(uiActions.stopLoading());
    }
});

const initialState: UserState = {
    users: [],
    activeUser: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        role: UserRole.USER,
        phoneNo: "",
        zipCode: "",
    },
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        addUser: (state, action) => {},
        setActiveUser: (state, action) => {
            state.activeUser = action.payload;
        },
        updateActiveUser: (state, action) => {
            state.activeUser = {
                ...state.activeUser,
                ...action.payload,
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            deleteUser.fulfilled,
            (state, action: PayloadAction<number>) => {
                state.users = state.users.filter(
                    (user) => user.id !== action.payload
                );
            }
        );
        builder.addCase(
            getUsers.fulfilled,
            (state, action: PayloadAction<User[]>) => {
                state.users = action.payload;
            }
        );
        builder.addCase(
            getUser.fulfilled,
            (state, action: PayloadAction<User>) => {
                state.activeUser = action.payload;
            }
        );
        builder.addCase(
            createUser.fulfilled,
            (state, action: PayloadAction<User>) => {
                state.users.push(action.payload);
            }
        );
        builder.addCase(
            updateUser.fulfilled,
            (state, action: PayloadAction<User>) => {
                state.users = state.users.map((user) =>
                    user.id === action.payload.id ? action.payload : user
                );
            }
        );
    },
});

export const userActions = userSlice.actions;
export const extraUserActions = {
    createUser,
    getUsers,
    getUser,
    updateUser,
    deleteUser,
};
export default userSlice.reducer;
