import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirm from "./DeleteConfirm";
import { User } from "../../../models/User.model";
import { extraUserActions } from "../../../store/user.slice";
import { useNavigate } from "react-router-dom";

const Users = () => {
    const users: User[] = useSelector((state: any) => state.user.users);
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(extraUserActions.getUsers());
    }, [dispatch]);
    const [deleteingUser, setDeleteingUser] = useState<User>();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleEditBtn = (id: number) => {
        navigate(`/dashboard/users/${id}`);
    }

    const handleDeleteBtn = (user: User) => {
        console.log(user);
        setDeleteingUser(user);
        setIsDeleting(true);
    };

    const handleDeleteUser = (id: number) => {
        dispatch(extraUserActions.deleteUser(id));
        setIsDeleting(false);
        setDeleteingUser(undefined);
    };

    const handleBagdropBtn = (id: number) => {
        navigate(`/dashboard/users/${id}/bagdrop`);
    }

    const handleCancelDeletion = () => {
        setIsDeleting(false);
        setDeleteingUser(undefined);
    };
    return (
        // users listing with edit and delete buttons
        <div className="px-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <h2>Users</h2>
                {/* <button className="btn btn-primary btn-sm" onClick={handleAddBtn}>Add</button> */}
            </div>
            <div className="mt-3 table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user: any, i: number) => (
                            <tr key={user.id}>
                                <td>{i+1}</td>
                                <td>{`${user.firstName} ${user.lastName}`}</td>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>

                                    <button className="btn btn-secondary btn-sm" onClick={() => handleBagdropBtn(user.id)}>
                                        Bag Drops
                                    </button>
                                    <button className="ms-2 btn btn-primary btn-sm" onClick={() => handleEditBtn(user.id)}>
                                        Edit
                                    </button>
                                    <button
                                        className="ms-2 btn btn-danger btn-sm"
                                        onClick={() => handleDeleteBtn(user)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {isDeleting && deleteingUser ? (
                <DeleteConfirm
                    user={deleteingUser}
                    isDeleting={isDeleting}
                    handleUserDelete={handleDeleteUser}
                    handleCancelDeletion={handleCancelDeletion}
                />
            ) : null}
        </div>
    );
};

export default Users;
