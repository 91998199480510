import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../models/User.model";
import { extraUserActions, userActions } from "../../../store/user.slice";

const UserDetail = () => {
    const { id } = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const activeUser: User = useSelector((state: any) => state.user.activeUser);
    useEffect(() => {
        if (!id) return;
        dispatch(extraUserActions.getUser(Number(id)));
    }, [id, dispatch]);

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(activeUser);
        if (!id) {
            return;
        }
        dispatch(extraUserActions.updateUser(activeUser));
        navigate("/dashboard/users");
    };

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.id) {
            case "firstName":
                dispatch(userActions.updateActiveUser({ firstName: e.target.value }));
                break;
            case "lastName":
                dispatch(userActions.updateActiveUser({ lastName: e.target.value }));
                break;
            case "email":
                dispatch(userActions.updateActiveUser({ email: e.target.value }));
                break;
            case "phoneNo":
                dispatch(userActions.updateActiveUser({ phoneNo: e.target.value }));
                break;
            case "zipCode":
                dispatch(userActions.updateActiveUser({ zipCode: e.target.value }));
                break;
        }
    };


    return (
        <div>
            {/* edit user form using React Bootstrap */}
            <div className="row justify-content-center px-3 mt-3">
                <div className="col-md-6 justify-content-between align-items-center">
                    <h2>User Detail</h2>
                </div>
                <div className="mt-3">
                    <form
                        className="row justify-content-center"
                        onSubmit={handleFormSubmit}
                    >
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">First Name</label>
                                <input
                                    id="firstName"
                                    type="text"
                                    className="form-control"
                                    value={activeUser.firstName}
                                    onChange={inputChangeHandler}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Last Name</label>
                                <input
                                    id="lastName"
                                    type="text"
                                    className="form-control"
                                    value={activeUser.lastName}
                                    onChange={inputChangeHandler}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input
                                    id="email"
                                    type="text"
                                    className="form-control"
                                    value={activeUser.email}
                                    onChange={inputChangeHandler}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Phone</label>
                                <input
                                    id="phoneNo"
                                    type="text"
                                    className="form-control"
                                    value={activeUser.phoneNo}
                                    onChange={inputChangeHandler}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Role</label>
                                <input
                                    id="role"
                                    type="text"
                                    className="form-control"
                                    value={activeUser.role}
                                    readOnly
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Zip Code</label>
                                <input
                                    id="zipCode"
                                    type="text"
                                    className="form-control"
                                    value={activeUser.zipCode}
                                    onChange={inputChangeHandler}
                                />
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    {id ? 'Update' : 'Add'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserDetail;
