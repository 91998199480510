export interface User {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    zipCode: string;
    phoneNo: string;
    role?: UserRole;
    createdAt?: Date;
    updatedAt?: Date;
  }

  export enum UserRole {
    ADMIN = 'ADMIN',
    USER = 'USER'
  }
