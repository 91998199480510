import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { useNavigate } from "react-router-dom";
import { DropOffLocation } from "../../../models/DropOffLocation.model";
import { useEffect } from "react";
import { dropOffActions } from "../../../store/drop-off-locations.slice";

const DropOffLocations = () => {
    const locations: DropOffLocation[] = useSelector(
        (state: any) => state.dropOffLocations.locations
    );
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(dropOffActions.getDropOffLocations());
    },[dispatch]);

    const editBtnHandler = (id: number) => {
        navigate(`/dashboard/drop-off-locations/${id}`);
    };

    const deleteBtnHandler = (id: number) => {
        dispatch(dropOffActions.deleteDropOffLocation(id));
    };

    return (
        // drop off locations with sr no, name, address, website, email, phone
        <div className="px-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <h2>Drop Off Locations</h2>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                        navigate("/dashboard/drop-off-locations/add")
                    }
                >
                    Add
                </button>
            </div>
            <div className="table-responsive mt-3">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Sr No</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Website</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th className="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locations.map((location, i) => (
                            <tr key={location.id}>
                                <td>{i + 1}</td>
                                <td>{location.name}</td>
                                <td>{location.address}</td>
                                <td>{location.website}</td>
                                <td>{location.email}</td>
                                <td>{location.phone}</td>
                                <td className="text-end">
                                    <button className="btn btn-primary btn-sm" onClick={() => editBtnHandler(location.id || 0)}>
                                        Edit
                                    </button>
                                    <button className="ms-2 btn btn-danger btn-sm" onClick={() => deleteBtnHandler(location.id || 0)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}

                        {/* Action buttons */}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DropOffLocations;
