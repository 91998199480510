import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DropOffLocation } from "../models/DropOffLocation.model";
import { uiActions } from "./ui.slice";
import locationApi from "../api/dropOffLocations.api";

export interface DropOffLocationState {
    locations: DropOffLocation[];
    activeLocation: DropOffLocation | null;
}

const getDropOffLocations = createAsyncThunk(
    "get/locations",
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            const locations = await locationApi.getLocations();
            return locations;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

const getDropOffLocationById = createAsyncThunk(
    "get/location",
    async (id: number, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            const location = await locationApi.getLocation(id);
            return location;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

const addDropOffLocation = createAsyncThunk(
    "add/location",
    async (location: DropOffLocation, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            const newLocation = await locationApi.addLocation(location);
            return newLocation;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

const updateDropOffLocation = createAsyncThunk(
    "update/location",
    async (location: DropOffLocation, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            const updatedLocation = await locationApi.updateLocation(location);
            return updatedLocation;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

const deleteDropOffLocation = createAsyncThunk(
    "delete/location",
    async (id: number, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            await locationApi.deleteLocation(id);
            return id;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

export const dropOffLocationExtraActions = {
    getDropOffLocations,
    getDropOffLocationById,
    addDropOffLocation,
    updateDropOffLocation,
    deleteDropOffLocation,
};

const initialState: DropOffLocationState = {
    locations: [],
    activeLocation: {
        id: 0,
        name: "",
        address: "",
        city: "",
        state: "",
        landmark: "",
        zipcode: "",
        latitude: 0,
        longitude: 0,
        website: "",
        email: "",
        phone: "",
    },
};

const dropOffLocationSlice = createSlice({
    name: "dropOffLocations",
    initialState,
    reducers: {
        updateActiveLocation: (state, action) => {
            state.activeLocation = {...state.activeLocation , ...action.payload};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDropOffLocations.fulfilled, (state, action) => {
            state.locations = action.payload;
        });
        builder.addCase(getDropOffLocationById.fulfilled, (state, action) => {
            state.activeLocation = action.payload;
        });
        builder.addCase(addDropOffLocation.fulfilled, (state, action) => {
            state.locations.push(action.payload);
        });
        builder.addCase(updateDropOffLocation.fulfilled, (state, action) => {
            state.locations = state.locations.map((location) => {
                if (location.id === action.payload.id) {
                    return action.payload;
                }
                return location;
            });
        });
        builder.addCase(deleteDropOffLocation.fulfilled, (state, action) => {
            state.locations = state.locations.filter((location) => {
                return location.id !== action.payload;
            });
        });
    },
});

export default dropOffLocationSlice.reducer;
export const dropOffActions = {
    ...dropOffLocationSlice.actions,
    ...dropOffLocationExtraActions,
};
