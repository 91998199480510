import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { hiwActions } from "../../../store/hiw.slice";

const HowItWorks = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(hiwActions.getHIW());
    }, [dispatch]);
    const hiws = useSelector((state: any) => state.hiw.hiws);

    const handleAddBtn = () => {
        navigate("/dashboard/how-it-works/add");
    };

    const handleEditBtn = (id: number) => {
        navigate(`/dashboard/how-it-works/${id}`);
    };

    const handleDeleteBtn = (id: number) => {
        dispatch(hiwActions.deleteHIW(id));
    };


    return (
        // users listing with edit and delete buttons
        <div className="px-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <h2>How It Works</h2>
                <button className="btn btn-primary btn-sm" onClick={() => handleAddBtn()}>Add</button>
            </div>
            <div className="mt-3 table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Title</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hiws.map((hiw: any, i: number) => (
                            <tr key={hiw.id}>
                                <td>{i+1}</td>
                                <td>{hiw.title}</td>
                                <td>
                                    <button className="ms-2 btn btn-primary btn-sm" onClick={() => handleEditBtn(hiw.id)}>
                                        Edit
                                    </button>
                                    <button
                                        className="ms-2 btn btn-danger btn-sm"
                                        onClick={() => handleDeleteBtn(hiw.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default HowItWorks
