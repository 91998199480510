import Header from "./Header/Header";
import { useSelector } from "react-redux";
import Router from "./routing/Router";

const Layout = () => {
    const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
    return (
        <div>
            {isLoggedIn && <Header />}
            <Router />
        </div>
    );
};

export default Layout;
