import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth.slice";

const Login = () => {
    const dispatch = useDispatch<any>();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');


    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'email':
                setEmail(e.target.value);
                break;
            case 'password':
                setPassword(e.target.value);
                break;
        }
    }

    const loginHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        console.log('loginHandler');
        dispatch(authActions.login({email, password}));
    }
  return (
    <div className="w-75 m-auto pt-5">
        <h2>
            Login
        </h2>
      <Form className="mt-5">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control name="email" type="email" placeholder="Enter email" onChange={inputChangeHandler} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control name="password" type="password" placeholder="Password" onChange={inputChangeHandler} />
        </Form.Group>
        <Button variant="primary" type="submit" onClick={loginHandler}>
          Login
        </Button>
      </Form>
    </div>
  );
};

export default Login;
