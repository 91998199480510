import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.scss";
import Layout from "./components/Layout";
import store from "./store";

function App() {
    return (
        <Provider store={store}>
            <Router>
                <div>
                    <Layout/>
                </div>
            </Router>
        </Provider>
    );
}

export default App;
