
import { Button, Modal } from "react-bootstrap"
import { User } from "../../../models/User.model";

interface DeleteConfirmProps {
    user: User;
    isDeleting: boolean;
    handleUserDelete: (id: number) => void;
    handleCancelDeletion: () => void;
}
const DeleteConfirm: React.FC<DeleteConfirmProps> = ({user, isDeleting, handleUserDelete, handleCancelDeletion}) => {



    const handleModalClose = () => {
        handleCancelDeletion();
    }
    const handleDelete = () => {
        handleUserDelete(user.id || 0);
    }


  return (
    <Modal show={isDeleting} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure you want to delete this user?</p>
            <p>Name: {user.firstName}</p><p>ID: {user.id}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete User
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default DeleteConfirm
