import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bagdropActions } from "../../../store/bagdrop.slice";
import { AppDispatch } from "../../../store";

const Bagdrops = () => {
    const dispatch: AppDispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const bagdrops = useSelector((state: any) => state.bagdrop.bagdrops);
    useEffect(() => {
        dispatch(bagdropActions.getBagdrops({userId: Number(id), populate: 'location'}));
    }, [dispatch, id]);

    return (
        // users listing with edit and delete buttons
        <div className="px-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <h2>Bagdrops</h2>
                {/* <button className="btn btn-primary btn-sm" onClick={handleAddBtn}>Add</button> */}
            </div>
            <div className="mt-3 table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Drop Off Location</th>
                            <th>Qty</th>
                            <th>Date</th>
                            {/* <th>Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {bagdrops.map((bagdrop: any, i: number) => (
                            <tr key={bagdrop.id}>
                                <td>{i+1}</td>
                                <td>{bagdrop.location ? bagdrop.location.name : 'Not Found'}</td>
                                <td>{bagdrop.quantity}</td>
                                <td>{bagdrop.orderDate}</td>
                                {/* <td>
                                    <button className="btn btn-primary btn-sm" >
                                        View
                                    </button>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Bagdrops;
