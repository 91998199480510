import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth.slice";
import userSlice from "./user.slice";
import dropOffLocationsLice from "./drop-off-locations.slice";
import bagdropSlice from "./bagdrop.slice";
import hiwSlice from "./hiw.slice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    bagdrop: bagdropSlice,
    hiw: hiwSlice,
    dropOffLocations: dropOffLocationsLice,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
