import { BASE_URL } from "../consts"

const login = async (email: string, password: string) => {
    const response = await fetch(`${BASE_URL}/api/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
}

const authApi = {
    login
}

export default authApi;
