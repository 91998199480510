import { BASE_URL } from "../consts";

export interface BagdropQueryOptions {
    userId?: number;
    populate?: string;
}

const getBagdrops = async (opts: BagdropQueryOptions) => {
    let url = `${BASE_URL}/api/bagdrops`
    if (opts.userId) {
        url = `${BASE_URL}/api/bagdrops?filter[user]=${opts.userId}`
    }
    if (opts.populate) {
        url = `${url}&populate=${opts.populate}`
    }
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if (!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const bagdropApi = {
    getBagdrops,
};


export default bagdropApi;
