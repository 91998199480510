import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/auth.slice';

const Navigation = () => {
    const dispatch: AppDispatch = useDispatch();
    const logoutHandler = () => {
        dispatch(authActions.logout());
    }
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">Navbar</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/dashboard/users">Users</Nav.Link>
            <Nav.Link href="/dashboard/drop-off-locations">Drop-off Locations</Nav.Link>
            <Nav.Link href="/dashboard/how-it-works">How it Works</Nav.Link>
          </Nav>
        </Container>

        <div className='d-flex'>
            <Button variant="outline-info" onClick={logoutHandler}>Logout</Button>
        </div>
      </Navbar>
    </>
  )
}

export default Navigation
