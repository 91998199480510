import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom";

interface ProtectedProps {
    children: React.ReactElement;
}
const Protected: React.FC<ProtectedProps> = ({children}) => {
    const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
    if (!isLoggedIn) {
        return (<Navigate to="/login" />);
    }
    return children;
}

export default Protected
