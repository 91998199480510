import { BASE_URL } from "../consts";
import { DropOffLocation } from "../models/DropOffLocation.model";

const getLocations = async () => {
    const response = await fetch(`${BASE_URL}/api/drop_off_locations`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if (!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const getLocation = async (id: number) => {
    const response = await fetch(
        `${BASE_URL}/api/drop_off_locations/${id}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
    );
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if (!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const addLocation = async (location: DropOffLocation) => {
    const data = {...location};
    delete data.id;
    const response = await fetch(`${BASE_URL}/api/drop_off_locations`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if (!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const updateLocation = async (location: DropOffLocation) => {
    const response = await fetch(
        `${BASE_URL}/api/drop_off_locations/${location.id}`,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(location),
        }
    );
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if (!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const deleteLocation = async (id: number) => {
    const response = await fetch(`${BASE_URL}/api/drop_off_locations/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const responseJson = await response.json();
    if (!response.ok) {
        throw new Error(responseJson.message);
    }
    if (!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const locationApi = {
    getLocations,
    getLocation,
    addLocation,
    updateLocation,
    deleteLocation,
};

export default locationApi;
