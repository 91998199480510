import { BASE_URL } from "../consts"
import { User } from "../models/User.model";

const getUsers = async () => {
    const resposne = await fetch(`${BASE_URL}/api/users`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
    });
    const responseJson = await resposne.json();
    if (!resposne.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const getUser = async (id: number) => {
    const resposne = await fetch(`${BASE_URL}/api/users/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
    });
    const responseJson = await resposne.json();
    if (!resposne.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const createUser = async (user: any) => {
    const resposne = await fetch(`${BASE_URL}/api/users`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user)
    });
    const responseJson = await resposne.json();
    if (!resposne.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};

const updateUser = async (id: number, user: User) => {
    const resposne = await fetch(`${BASE_URL}/api/users/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(user)
    });

    const responseJson = await resposne.json();
    if (!resposne.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson.data;
};


const deleteUser = async (id: number) => {
    const resposne = await fetch(`${BASE_URL}/api/users/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
    });
    const responseJson = await resposne.json();
    if (!resposne.ok) {
        throw new Error(responseJson.message);
    }
    if(!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson;
}

const userApi = {
    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser
}

export default userApi;
