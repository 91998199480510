import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Bagdrop } from "../models/Bagdrop.model";
import bagdropApi, { BagdropQueryOptions } from "../api/bagdrop.api";
import { uiActions } from "./ui.slice";

export interface DropOffLocationState {
    bagdrops: Bagdrop[];
    activeBagdrop: Bagdrop | null;
}

const initilState: DropOffLocationState = {
    bagdrops: [],
    activeBagdrop: {
        id: 0,
        user: 0,
        location: 0,
        quantity: 0,
        orderDate: "",
    },
}

const getBagdrops = createAsyncThunk(
    "get/bagdrop",
    async (opts: BagdropQueryOptions, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            const locations = await bagdropApi.getBagdrops(opts);
            return locations;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

const bagdropSlice = createSlice({
    name: "bagdrop",
    initialState: initilState,
    reducers: {
        setActiveBagdrop: (state, action) => {
            state.activeBagdrop = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBagdrops.fulfilled, (state, action) => {
            state.bagdrops = action.payload;
        });
    }
});

export default bagdropSlice.reducer;
export const bagdropActions = {
    ...bagdropSlice.actions,
    getBagdrops,
};
