import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { DropOffLocation } from "../../../models/DropOffLocation.model";
import { useEffect } from "react";
import { dropOffActions } from "../../../store/drop-off-locations.slice";

const DropOffLocationDetail = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const activeLocation: DropOffLocation = useSelector(
        (state: any) => state.dropOffLocations.activeLocation
    );
    useEffect(() => {
        if (!id) return;
        dispatch(dropOffActions.getDropOffLocationById(Number(id)));
    }, [id, dispatch]);

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!id) {
            dispatch(dropOffActions.addDropOffLocation(activeLocation));
        } else {
            dispatch(dropOffActions.updateDropOffLocation(activeLocation));
        }
        navigate("/dashboard/drop-off-locations");
    };

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const location = { ...activeLocation };
        switch (e.target.id) {
            case "name":
                location.name = e.target.value;
                break;
            case "address":
                location.address = e.target.value;
                break;
            case "city":
                location.city = e.target.value;
                break;
            case "state":
                location.state = e.target.value;
                break;
            case "landmark":
                location.landmark = e.target.value;
                break;
            case "zipcode":
                location.zipcode = e.target.value;
                break;
            case "latitude":
                location.latitude = Number(e.target.value);
                break;
            case "longitude":
                location.longitude = Number(e.target.value);
                break;
            case "website":
                location.website = e.target.value;
                break;
            case "phone":
                location.phone = e.target.value;
                break;
            case "email":
                location.email = e.target.value;
                break;
        }
        dispatch(dropOffActions.updateActiveLocation(location));
    };
    return (
        <div className="container mb-5">
            <h1 className="mt-3">Drop Off Location Detail</h1>
            <div className="row">
                <form className="col-md-12 mx-auto" onSubmit={handleFormSubmit}>
                    <div className="form-group mb-3">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={activeLocation.name}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            value={activeLocation.address}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            value={activeLocation.city}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="zipcode">Zipcode</label>
                        <input
                            type="text"
                            className="form-control"
                            id="zipcode"
                            name="zipcode"
                            value={activeLocation.zipcode}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="state">State</label>
                        <input
                            type="text"
                            className="form-control"
                            id="state"
                            name="state"
                            value={activeLocation.state}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="landmark">Landmark</label>
                        <input
                            type="text"
                            className="form-control"
                            id="landmark"
                            name="landmark"
                            value={activeLocation.landmark}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="latitude">Latitude</label>
                        <input
                            type="number"
                            className="form-control"
                            id="latitude"
                            name="latitude"
                            value={activeLocation.latitude}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="longitude">Longitude</label>
                        <input
                            type="number"
                            className="form-control"
                            id="longitude"
                            name="longitude"
                            value={activeLocation.longitude}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="website">Website</label>
                        <input
                            type="url"
                            className="form-control"
                            id="website"
                            name="website"
                            value={activeLocation.website}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            value={activeLocation.email}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="phone"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={activeLocation.phone}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Add
                    </button>
                </form>
            </div>
        </div>
    );
};

export default DropOffLocationDetail;
