import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uiActions } from "./ui.slice";
import { HIW } from "../models/HIW.model";
import hiwApi from "../api/hiw.api";

interface HIWState {
    hiws: HIW[];
    activeHIW: HIW | null;
}

const getHIW = createAsyncThunk("hiw/getAll", async (_, thunkAPI) => {
    try {
        thunkAPI.dispatch(uiActions.startLoading());
        const hiw = await hiwApi.getAll();
        return hiw;
    } catch (error) {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
    } finally {
        thunkAPI.dispatch(uiActions.stopLoading());
    }
});

const getHIWById = createAsyncThunk("hiw/getById", async (id: number, thunkAPI) => {
    try {
        thunkAPI.dispatch(uiActions.startLoading());
        const hiw = await hiwApi.get(id);
        return hiw;
    } catch (error) {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
    } finally {
        thunkAPI.dispatch(uiActions.stopLoading());
    }
});

const createHIW = createAsyncThunk(
    "hiw/create",
    async (hiw: HIW, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            const newHIW = await hiwApi.create(hiw);
            return newHIW;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

const updateHIW = createAsyncThunk(
    "hiw/update",
    async (hiw: HIW, thunkAPI) => {
        try {
            thunkAPI.dispatch(uiActions.startLoading());
            if (!hiw.id) {
                throw new Error("Problem with hiw id");
            }
            const updatedHIW = await hiwApi.update(hiw.id, hiw);
            return updatedHIW;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        } finally {
            thunkAPI.dispatch(hiwActions.setActiveHIW(initialState.activeHIW));
            thunkAPI.dispatch(uiActions.stopLoading());
        }
    }
);

const deleteHIW = createAsyncThunk("hiw/delete", async (id: number, thunkAPI) => {
    try {
        thunkAPI.dispatch(uiActions.startLoading());
        await hiwApi.remove(id);
        return id;
    } catch (error) {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
    } finally {
        thunkAPI.dispatch(uiActions.stopLoading());
    }
});

const initialState: HIWState = {
    hiws: [],
    activeHIW: {
        id: 0,
        title: "",
        content: "",
    },
};



const hiwSlice = createSlice({
    name: "hiw",
    initialState,
    reducers: {
        setActiveHIW: (state, action: PayloadAction<HIW | null>) => {
            state.activeHIW = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getHIW.fulfilled, (state, action) => {
            state.hiws = action.payload;
        });
        builder.addCase(getHIWById.fulfilled, (state, action) => {
            state.activeHIW = action.payload;
        });
        builder.addCase(createHIW.fulfilled, (state, action) => {
            state.hiws.push(action.payload);
        });
        builder.addCase(updateHIW.fulfilled, (state, action) => {
            const index = state.hiws.findIndex(hiw => hiw.id === action.payload.id);
            if (index !== -1) {
                state.hiws[index] = action.payload;
            }
        });
        builder.addCase(deleteHIW.fulfilled, (state, action) => {
            const index = state.hiws.findIndex(hiw => hiw.id === action.payload);
            if (index !== -1) {
                state.hiws.splice(index, 1);
            }
        });
    }
});

export const hiwActions = {
    ...hiwSlice.actions,
    getHIW,
    getHIWById,
    createHIW,
    updateHIW,
    deleteHIW,
};

export default hiwSlice.reducer;
